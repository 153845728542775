.tttGame {
  
  text-align: center;
  align-self: center;
  margin: 20px auto;
  /* background-color: rgb(235, 226, 245); */
  background-repeat: no-repeat;
  /* background-size: 90%; */
  max-width: 90%;
}
.tttbackground2 {
  background-image: url("https://media4.giphy.com/media/3o7WTqRKlVRj0wsYQo/giphy.gif?cid=ecf05e475o9goo8i63ltmgqkm0de9joeyo1nost2d8mlhu6c&rid=giphy.gif&ct=g");
  background-repeat: no-repeat;
  background-size: cover;
}
.tttbackground1 {
  background-image: url("https://media3.giphy.com/media/xvZOuJeLij0W9IwCdH/giphy.gif?cid=790b7611f7d6f1d8b24b92cbf17ad6945c454e19c22b887d&rid=giphy.gif&ct=g");
}
.tttbackboard {
  background-color: rgb(235, 226, 245);
  opacity: 1;
  width: fit-content;
  margin: 0 auto;
  padding: 15px;
   
}
.tttH1 {
  font-size: 70px;
  transform: rotate(-10deg);
}
.tttbutton {
  font-size: xx-large;

}
.tttmiddle {
  /* width: 100%; */
  display: flex;
  justify-content: space-around;
  margin: 15px 15px 0 15px;
}
.tttside1, .tttside2 {
  padding: 15px;
  font-size: 50px;
}
.tttgameBoard {
  max-height: fit-content;
  max-width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 3px;
  
}
.tttbuttondiv {
  border: 5px solid black;
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px;
  font-size: 90px;
  background-color: rgb(186, 245, 225);
  line-height: 1
}

.tttmessageBoard {
  font-size: 50px;
}

.tttbuttondivimg{
  /* border: 5px solid black; */
  height: 100px;
  width: 100px;
  max-height: 100%;
  max-width: 100%;
  background-color: rgb(186, 245, 225);
  line-height: 1;
}