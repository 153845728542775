.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hidden {
  background-color: transparent;
  color:   transparent;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* div .navbar {
  background-color: rgba(15, 80, 120, 0.916)!important;
} */

/* :root {
  --bs-dark-rgb: 15,80,120;
} */

:root {
  --bs-dark-rgb: 39,76,111;
}

body {
  background-color: rgba(157, 199, 232, 0.916)
}

.skillspage{
  max-width: 100%;
}

.skillsBoxAll{
  /* border: 1px solid rgb(243, 32, 32); */
  display: flex;
  justify-content: space-evenly
}

.skillsBoxItem{
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 55px;
}

.skills {
  max-width: 50px;
  max-height: 50px
}

.skillsName {
  /* border: 1px solid black; */
  /* display: flex;
  align-self: center */
}

.cant_touch_this {
  position: relative;
  /* border: 10px solid transparent; */
}


.carousel {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  max-width: 80%;
}

.carousel-inner {
  /* max-width: 90%; */
  color: black;

}

.btn-outline-dark {
  background-color: rgba(15, 80, 120, 0.916);;
  border-color: rgba(15, 80, 120, 0.916);
  color: white
}

.btn-outline-dark:active {
  background-color: rgba(15, 80, 120, 0.916);;
  border-color: rgba(15, 80, 120, 0.916);
  color: white
}
.btn-outline-dark:link {
  background-color: rgba(15, 80, 120, 0.916);;
  border-color: rgba(15, 80, 120, 0.916);
  color: white
}
.btn-outline-dark:focus {
  background-color: rgba(15, 80, 120, 0.916);;
  border-color: rgba(15, 80, 120, 0.916);
  color: white
}
.btn-outline-dark:visited {
  background-color: rgba(15, 80, 120, 0.916);;
  border-color: rgba(15, 80, 120, 0.916);
  color: white
}
/* .btn-outline-dark {
  border-color: rgba(15, 80, 120, 0.916);
  color: rgba(15, 80, 120, 0.916);
} */

.btn-outline-dark:hover {
  background-color: rgba(51, 122, 176, 0.916);
  color: white
  /* color: rgba(15, 80, 120, 0.916); */
}



/* .Wheel { */
  /* background-image: url("./images/profilepic.png"); */
  /* background-size: cover; */
  /* image-orientation: fixed !important;
  transform: translate(-50%, -50%) rotate(0) !important; */
/* } */

.react-pdf__Page__canvas {

  margin: 0 auto;
}
